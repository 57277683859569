<template>
  <v-container class="fill-heigt" style="background-color:#E4E4E4;" fluid>
    <v-card class="mx-auto flexcard altura" width="800">
      <div id="content">
        <v-row>
          <v-col cols="5" style="padding-top:0;">
            <v-responsive justify="right" align="right">
              <v-card
                flat 
                dark
                color="#10253F"
                class="px-3 mt-n2"
                width="70"
                height="120"
              >
                <div class="py-7"></div>
                <v-card-actions>
                  <v-row>
                    <v-col cols="12" v-if="this.candidate">
                      <span class="mt-n3 text-uppercase">{{
                        firstCharName
                      }}</span
                      ><span style="color:#F7A400">/</span
                      ><span class="mt-3 text-uppercase">{{
                        firstCharLastName
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-responsive>
          </v-col>
          <v-col cols="7">
            <div class="pt-6"></div>
            <div class="title font-weight-bold text-uppercase">
              <span class="title font-weight-light ">{{ candidate.name }}</span>
              {{ candidate.lastName }}
            </div>
            <div class="caption text--disabled subtitle">
              <span>{{ candidate.profession }}</span>
            </div>
          </v-col>
        </v-row>
        <div class="pt-3"></div>
        <div class="scroll">
          <v-row>
            <v-col cols="4" class="perfil">
              <div
                class="pickDown"
                style="background: #F7F7FF; width:100%; "
              >
                <v-avatar
                  size="70"
                  style="border-radius:50%; margin-top:-40px; margin-left:35px; position:absolute;"
                >
                  <img
                    :src="
                      candidate.photo
                        ? `${apiUrl}/images/candidate/${candidate.photo}`
                        : `/avatar.png`
                    "
                    :alt="candidate.name"
                  />
                </v-avatar>
                <div class="py-9"></div>
                <v-card-text>
                  <p class="font-weight-bold text-uppercase">Perfil</p>
                  <v-divider
                    class="mb-3"
                    style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                  ></v-divider>
                  <p></p>
                  <p class="caption mb-3">
                    {{ LimitText(candidate.presentation, "perfil") }}
                  </p>
                  <div class="py-4"></div>
                  <p class="font-weight-bold text-uppercase">Dados Pessoais</p>
                  <v-divider
                    class="mb-3"
                    style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                  ></v-divider>
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-title class="caption font-regular"
                        >Sexo</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{ candidate.genre }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-title class="caption font-regular"
                        >Nacionalidade</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{ candidate.nationality }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-title class="caption font-regular"
                        >Data de nascimento</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{
                          formatDate(candidate.dateBirth)
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <div class="py-4"></div>
                  <p class="font-weight-bold text-uppercase">Contactos</p>
                  <v-divider
                    class="mb-3"
                    style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                  ></v-divider>
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-title class="caption font-regular"
                        >Telefone</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{ candidate.telephone }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <div v-if="profileLength < 200">
                    <v-list-item style="padding-left:0;">
                      <v-list-item-content style="padding-top:0;">
                        <v-list-item-title class="caption font-regular"
                          >E-mail</v-list-item-title
                        >
                        <v-list-item-subtitle
                          class="caption font-regular"
                          style="text-overflow:inherit; white-space:normal;"
                          >{{ candidate.email }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-card-text>
              </div>
            </v-col>

            <v-col cols="8" style="box-sizing:border-box;" class="mb-12">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="9">
                    <p class="font-weight-bold text-uppercase">
                      EXPERIÊCIA PROFISSIONAL
                      <!-- {{ExpePrePosi}} -->
                    </p>
                    <v-divider
                      class="mb-3"
                      style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                    ></v-divider>
                  </v-col>
                </v-row>
                <v-row v-for="(expe, index) in Experience[0]" :key="index">
                  <v-col cols="3">
                    <p class="caption">
                      {{ formatDateExperience(expe.startDate) }} -
                      {{ formatDateExperience(expe.endDate) }}
                    </p>
                  </v-col>
                  <v-col cols="9">
                    <v-list-item style="padding-left:0;">
                      <v-list-item-content style="padding-top:0;">
                        <v-list-item-title
                          class="body-2 font-italic font-weight-bold"
                          >{{ expe.office }}</v-list-item-title
                        >
                        <v-list-item-subtitle class="body-2 font-italic">{{
                          expe.entity
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <p class="caption mb-3" v-if="index === 2">
                      {{ LimitText_(expe.description) }}
                    </p>
                    <p class="caption mb-3" v-else>
                      {{ LimitText(expe.description, "experiencia") }}
                    </p>
                  </v-col>
                </v-row>
                <div
                  id="divide"
                  v-if="ElementLength <= 10 && StringLength <= 600"
                >
                  <v-row no-gutters>
                    <v-col cols="9">
                      <p class="font-weight-bold text-uppercase">
                        Skills/habilidades
                      </p>
                      <v-divider
                        class="mb-3"
                        style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                      ></v-divider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="6"
                      v-for="(skill, index) in candidate.otherSkills"
                      :key="index"
                      class="py-0 my-0"
                    >
                      <span class="subtitle body-2 font-italic">{{
                        LimitText(skill, "skill")
                      }}</span>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="ElementLength <= 10 && StringLength < 600">
                  <v-row class="pt-10" no-gutters>
                    <v-col cols="9">
                      <p class="font-weight-bold text-uppercase">Línguas</p>
                      <v-divider
                        class="mb-3"
                        style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                      ></v-divider>
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(language, index) in candidate.languages"
                    :key="index"
                    class="afinar"
                  >
                    <v-col cols="5">
                      <span class="caption font-weight-medium textLimit">
                        {{ $t(language.name) }}
                      </span>
                    </v-col>
                    <v-col cols="7">
                      <span
                        class="body-2 font-weight-bold text-capitalize "
                        style="text-overflow: ellipsis;"
                      >
                        {{ $t(language.level) }}
                      </span>
                    </v-col>
                  </v-row>

                  <div
                    v-if="
                      StringLength < 440 &&
                        candidate.languages.length <= 3 &&
                        ElementLength <= 10 &&
                        Experience[0].length < 1
                    "
                  >
                    <v-row no-gutters class="pt-10">
                      <v-col cols="9">
                        <p class="font-weight-bold text-uppercase">
                          Nível Académico
                        </p>
                        <v-divider
                          class="mb-3"
                          style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                        ></v-divider>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(formation, index) in candidate.formation"
                      :key="`${index}kk`"
                    >
                      <v-col cols="3" class="d-flex flex-row">
                        <p class="caption">
                          {{ formatDateExperience(formation.startDate) }}
                          -
                          {{ formatDateExperience(formation.endDate) }}
                        </p>
                      </v-col>
                      <v-col cols="9" class="d-flex flex-column">
                        <div>
                          <span
                            class="body-2 font-italic font-weight-bold"
                            v-if="formation.course"
                            >{{ formation.level }} em
                            {{ $t(formation.course) }}</span
                          >
                        </div>
                        <div>
                          <span class="body-2 font-italic text--disabled">{{
                            formation.institution
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <v-card class="mx-auto flexcard altura espacoV break" width="800">
      <div id="content2">
        <v-row>
          <v-col cols="4" class="perfil2">
            <div style="background: #F7F7FF; width:100%; height:100%">
              <v-card-text>
                <div v-if="profileLength > 200">
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{ candidate.telephone2 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item style="padding-left:0;">
                    <v-list-item-content style="padding-top:0;">
                      <v-list-item-title class="caption font-regular"
                        >E-mail</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="caption font-regular"
                        style="text-overflow:inherit; white-space:normal;"
                        >{{ candidate.email }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div class="py-4"></div>
                <p class="font-weight-bold text-uppercase">ENDEREÇO</p>
                <v-divider
                  class="mb-3"
                  style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                ></v-divider>
                <v-list-item style="padding-left:0;">
                  <v-list-item-content style="padding-top:0;">
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ candidate.address }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <div class="py-4"></div>
                <p class="font-weight-bold text-uppercase">SOCIAL</p>
                <v-divider
                  class="mb-3"
                  style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                ></v-divider>
                <v-list-item style="padding-left:0;">
                  <v-list-item-content style="padding-top:0;">
                    <v-list-item-title class="caption font-regular"
                      >Linkedin</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ candidate.media.linkedin }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="padding-left:0;">
                  <v-list-item-content style="padding-top:0;">
                    <v-list-item-title class="caption font-regular"
                      >Facebook</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ candidate.media.facebook }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item style="padding-left:0;">
                  <v-list-item-content style="padding-top:0;">
                    <v-list-item-title class="caption font-regular"
                      >Twitter</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ candidate.media.twitter }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <div class="py-4"></div>
                <p class="font-weight-bold text-uppercase">Referências</p>
                <v-divider
                  class="mb-3"
                  style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                ></v-divider>

                <v-list-item
                  style="padding-left:0;"
                  v-for="reference in candidate.references"
                  :key="reference.fullName"
                >
                  <v-list-item-content style="padding-top:0;">
                    <v-list-item-title class="caption font-regular">{{
                      reference.fullName
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ reference.office }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="caption font-regular"
                      style="text-overflow:inherit; white-space:normal;"
                      >{{ reference.telephone }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </div>
          </v-col>
          <!-- <pre>{{ExpePrePosi_}}</pre> -->
          <v-col cols="8" style="box-sizing:border-box;" class="mb-12">
            <v-card-text>
              <div v-if="ExpePrePosi_ > 880">
                <v-row class="go_up">
                  <v-col cols="3">
                    <div class="caption"></div>
                  </v-col>
                  <v-col cols="9">
                    <v-list-item style="padding-left:0;">
                      <v-list-item-content style="padding-top:0;">
                        <v-list-item-title
                          class="body-2 font-italic font-weight-bold"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          class="body-2 font-italic"
                          >{{
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <div class="caption mb-3" v-if="Experience[0][2]">
                      {{ LimitText_dwn(Experience[0][2].description) }}
                    </div>
                  </v-col>
                </v-row>
              </div>
              <!-- <pre>{{level[0]}}</pre> -->
              <div
                v-if="
                  ExpePrePosi >= 822 || (Experience.length > 1 && Experience[1])
                "
              >
                <div v-if="Experience[1]">
                  <v-row v-for="(expe, index) in Experience[1]" :key="index">
                    <v-col cols="3">
                      <p class="caption">
                        {{ formatDateExperience(expe.startDate) }} -
                        {{ formatDateExperience(expe.endDate) }}
                      </p>
                    </v-col>
                    <v-col cols="9">
                      <v-list-item style="padding-left:0;">
                        <v-list-item-content style="padding-top:0;">
                          <v-list-item-title
                            class="body-2 font-italic font-weight-bold"
                            >{{ expe.office }}</v-list-item-title
                          >
                          <v-list-item-subtitle class="body-2 font-italic">{{
                            expe.entity
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <p class="caption mb-3" v-if="index === 2">
                        {{ LimitText_(expe.description) }}
                      </p>
                      <p class="caption mb-3" v-else>
                        {{ LimitText(expe.description, "experiencia") }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>

              <div id="divide" v-if="StringLength > 600">
                <v-row no-gutters>
                  <v-col cols="9">
                    <p class="font-weight-bold text-uppercase">
                      Skills/habilidades
                    </p>
                    <v-divider
                      class="mb-3"
                      style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                    ></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    v-for="(skill, index) in candidate.otherSkills"
                    :key="index"
                    class="py-0 my-0"
                  >
                    <span class="subtitle body-2 font-italic">{{
                      LimitText(skill, "skill")
                    }}</span>
                  </v-col>
                </v-row>
              </div>
              <div v-if="StringLength >= 600">
                <v-row class="pt-10" no-gutters>
                  <v-col cols="9">
                    <p class="font-weight-bold text-uppercase">Línguas</p>
                    <v-divider
                      class="mb-3"
                      style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                    ></v-divider>
                  </v-col>
                </v-row>

                <v-row
                  v-for="(language, index) in candidate.languages"
                  :key="index"
                  class="afinar"
                >
                  <v-col cols="5">
                    <p class="caption font-weight-medium textLimit">
                      {{ $t(language.name) }}
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <p class="body-2 font-weight-bold text-capitalize">
                      {{ $t(language.level) }}
                    </p>
                  </v-col>
                </v-row>
              </div>
              <!-- {{ StringLength }} ====//=====
            {{ candidate.languages.length }} =======//=====
            {{ ElementLength }} =====///====={{ Experience[0].length }} -->
              <div
                v-if="
                  (StringLength <= 1320 &&
                    candidate.languages.length >= 1 &&
                    ElementLength >= 1 &&
                    Experience[0].length > 1) ||
                    StringLength <= 440
                "
              >
                <v-row no-gutters class="pt-10">
                  <v-col cols="9">
                    <p class="font-weight-bold text-uppercase">
                      Nível Académico
                    </p>
                    <v-divider
                      class="mb-3"
                      style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                    ></v-divider>
                  </v-col>
                </v-row>

                <v-row
                  v-for="(formation, index) in level[0]"
                  :key="`${index}kk`"
                >
                  <v-col cols="3" class="d-flex flex-row">
                    <p class="caption">
                      {{ formatDateExperience(formation.start_date) }}
                      -
                      {{ formatDateExperience(formation.end_date) }}
                    </p>
                  </v-col>
                  <v-col cols="9" class="d-flex flex-column">
                    <div>
                      <span
                        class="body-2 font-italic font-weight-bold"
                        v-if="formation.course"
                        >{{ formation.level }} em
                        {{ $t(formation.course) }}</span
                      >
                    </div>
                    <div>
                      <span class="body-2 font-italic text--disabled">{{
                        formation.institution
                      }}</span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card
      class="mx-auto flexcard altura break"
      width="800"
      v-if="level.length > 1"
    >
      <v-row>
        <v-col cols="4" class="perfil2">
          <div style="background: #F7F7FF; width:100%; height:100%">
            <v-card-text> </v-card-text>
          </div>
        </v-col>

        <v-col cols="8" style="box-sizing:border-box;" class="mb-12">
          <v-card-text>
            <div v-if="ExpePrePosi >= 1320">
              <v-row no-gutters class="pt-10">
                <v-col cols="9">
                  <p class="font-weight-bold text-uppercase">Nível Académico</p>
                  <v-divider
                    class="mb-3"
                    style="background-color:#F7A400; border:1px solid #F7A400; width:40px; margin-top: -10px;"
                  ></v-divider>
                </v-col>
              </v-row>

              <v-row v-for="(formation, index) in level[0]" :key="`${index}kk`">
                <v-col cols="3" class="d-flex flex-row">
                  <p class="caption">
                    {{ formatDateExperience(formation.start_date) }}

                    -
                    {{ formatDateExperience(formation.end_date) }}
                  </p>
                </v-col>
                <v-col cols="9" class="d-flex flex-column">
                  <div>
                    <span
                      class="body-2 font-italic font-weight-bold"
                      v-if="formation.course"
                      >{{ formation.level }} em {{ $t(formation.course) }}</span
                    >
                  </div>
                  <div>
                    <span class="body-2 font-italic text--disabled">{{
                      formation.institution
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="level.length > 1">
              <v-row v-for="(formation, index) in level[1]" :key="`${index}kk`">
                <v-col cols="3" class="d-flex flex-row">
                  <p class="caption">
                    {{ formatDateExperience(formation.start_date) }}
                    -
                    {{ formatDateExperience(formation.end_date) }}
                  </p>
                </v-col>
                <v-col cols="9" class="d-flex flex-column">
                  <div>
                    <span
                      class="body-2 font-italic font-weight-bold"
                      v-if="formation.course"
                      >{{ formation.level }} em {{ $t(formation.course) }}</span
                    >
                  </div>
                  <div>
                    <span class="body-2 font-italic text--disabled">{{
                      formation.institution
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <!-- <ShareCVDialog /> -->
  </v-container>
</template>

<script>
import { GET_CANDIDATE_BY_ID_QUERY } from '../graphql/Query.gql'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { API_URL } from '@/api'
export default {
  name: 'CV',
  data: () => ({
    apiUrl: API_URL,
    candidate: {},
    candidateId: undefined,
    skipQuery: true,
  }),
  apollo: {
    candidate: {
      query: GET_CANDIDATE_BY_ID_QUERY,
      variables() { 
        return {
          id: this.candidateId
        }
      },
      skip() {
        return this.skipQuery
      },
      result ({ data, loading }) {
        if (!loading) {
          this.candidate = data.candidate
        }
      },
    },
  },
  created () {
    this.candidateId = this.$route.params.id
    this.fetchCandidate()
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
    ExpePrePosi() {
      var sum = 0;
      for (
        let index = 0;
        index < this.candidate.professionalExperience.length;
        index++
      ) {
        sum += this.candidate.professionalExperience[index].description.length;
      }
      return sum;
    },
    ExpePrePosi_() {
      var sum = 0;

      for (
        let index = 0;
        index < this.candidate.professionalExperience.length;
        index++
      ) {
        if (index < 2) {
          sum += this.candidate.professionalExperience[index].description
            .length;
        }
      }

      return sum;
    },
    Experience() {
      let array = this.candidate.professionalExperience;
      let size = 3;
      if (this.ExpePrePosi_ <= 840) {
        size = 4;
      }
      let result = [];
      let arrayCopy = [...array];
      while (arrayCopy.length > 0) {
        result.push(arrayCopy.splice(0, size));
      }
      return result;
    },
    ElementLength() {
      return this.candidate.otherSkills.length;
    },
    firstCharName() {
      return this.candidate.name.charAt(0);
    },
    firstCharLastName() {
      return this.candidate.lastName.charAt(0);
    },
    profileLength() {
      return this.candidate.presentation
        ? this.candidate.presentation.length
        : 0;
    },
    StringLength() {
      let sum = 0;
      this.candidate.professionalExperience.forEach((element) => {
        if (element.description.length > 200) {
          sum += element.description.length;
        } else if (
          element.description.length > 0 &&
          element.description.length < 200
        ) {
          sum += element.description.length;
        }
      });
      return sum;
    },
    level() {
      let array = this.candidate.formation;
      let size = 6;
      if (this.ExpePrePosi >= 822) {
        size = 4;
        if (this.ExpePrePosi < 600) {
          size = 10;
        }
      }
      let result = [];
      let arrayCopy = [...array];
      while (arrayCopy.length > 0) {
        result.push(arrayCopy.splice(0, size));
      }
      return result;
    },
  },
  methods: {
    async fetchCandidate () {
      this.$apollo.queries.candidate.skip = false
      await this.$apollo.queries.candidate.refetch()
    },
    formatDateExperience(date) {
      return moment(date).format('YYYY')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    LimitText(text, place) {
      if (place === 'experiencia') {
        text = text.slice(0, 440)
      }
      if (place === 'perfil') {
        text = text.slice(0, 300)
      }
      if (place === 'skill') {
        text = text.slice(0, 30)
      }
      return text
    },
    LimitText_(text) {
      if (this.ExpePrePosi_ === 840) {
        return text.slice(0, 40)
      } else if (this.ExpePrePosi_ > 800 && this.ExpePrePosi_ < 840) {
        return text.slice(0, 80)
      } else if (this.ExpePrePosi_ > 760 && this.ExpePrePosi_ < 800) {
        return text.slice(0, 115)
      } else if (this.ExpePrePosi_ > 700 && this.ExpePrePosi_ < 760) {
        return text.slice(0, 153)
      } else if (this.ExpePrePosi_ > 660 && this.ExpePrePosi_ < 700) {
        return text.slice(0, 220)
      } else if (this.ExpePrePosi_ > 600 && this.ExpePrePosi_ < 660) {
        return text.slice(0, 290)
      } else {
        return text
      }
    },
    LimitText_dwn(text) {
      if (this.ExpePrePosi_ === 840) {
        return text.slice(40, 440)
      } else if (this.ExpePrePosi_ > 800 && this.ExpePrePosi_ < 840) {
        return text.slice(80, 440)
      } else if (this.ExpePrePosi_ > 760 && this.ExpePrePosi_ < 800) {
        return text.slice(115, 440)
      } else if (this.ExpePrePosi_ > 700 && this.ExpePrePosi_ < 760) {
        return text.slice(153, 440)
      } else if (this.ExpePrePosi_ > 660 && this.ExpePrePosi_ < 700) {
        return text.slice(220, 440)
      } else if (this.ExpePrePosi_ > 600 && this.ExpePrePosi_ < 660) {
        return text.slice(290, 440)
      } else {
        return text
      }
    }
  }
}
</script>
<style scoped>
body {
  background-color: #10253f !important;
}
html {
  background-color: #10253f !important;
}
.altura {
  height: 920px;
}
.perfil {
  height: 1000%;
}
.perfil2 {
  height: 930px;
}
.afinar {
  margin-bottom: -9%;
}
.go_up {
  margin-top: -15%;
}
.break {
  margin-top: 1%;
}
@media screen and (min-width: 300px) and (max-width: 959px) {
  .perfil {
    height: 2090px;
  }
  .altura {
    height: 1720px;
  }
  .perfil2 {
    height: 1730px;
  }
  .perfil2 {
    height: 1730px;
  }
  .pickDown {
    max-height: 1000px;
  }
  .pickAlin {
    margin-right: 10%;
  }
  .afinar {
    margin-bottom: -20%;
  }
  .textLimit {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .break {
    margin-top: 5%;
  }
}
</style>
